import { useContext, useEffect } from 'react';
import { CountryContext } from '~/context/i18n';
import { useGlobalState } from '~/context/global';
import { joinGovernmentCovidData } from './helpers/dataJoining';
import { Map, AnyLayer } from 'mapbox-gl';
import {
  mapboxUSACountyBoundaries,
  usaCountyBoundariesSource,
  mapboxStatesAndMunicipalities,
  statesAndMunicipalitiesSource,
  governmentCovidCasesUSACountyFills,
  governmentCovidDeathsUSACountyFills,
  governmentCovidCasesStatesAndMunicipalitiesFills,
  governmentCovidDeathsStatesAndMunicipalitiesFills,
} from './layers';

export const useGovernmentCovidData = (map?: Map): void => {
  const { statsByCountry } = useGlobalState();
  const { country, adm1Boundaries } = useContext(CountryContext);

  const countryCode = country.code.toUpperCase();
  const { populationData: governmentCovidData } =
    statsByCountry[countryCode] || {};

  useEffect(
    function loadDataIntoMap() {
      if (map && governmentCovidData?.features?.length) {
        addUSACountyBoundaries(map);
        addStatesAndMunicipalities(map);
        moveGeographyLayersToFront(map);
        joinGovernmentCovidData(
          map,
          countryCode,
          governmentCovidData.features,
          adm1Boundaries
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, governmentCovidData]
  );
};

/**
 * Fileprivate function for adding source and layers that paint CV-19 confirmed cases and deaths throughout the US counties.
 *
 * @param map
 */

const addUSACountyBoundaries = (map?: Map): void => {
  if (map && !map.getSource(mapboxUSACountyBoundaries.sourceId)) {
    map.addSource(
      mapboxUSACountyBoundaries.sourceId,
      usaCountyBoundariesSource
    );
    map.addLayer(governmentCovidCasesUSACountyFills as AnyLayer);
    map.addLayer(governmentCovidDeathsUSACountyFills as AnyLayer);
  }
};

/**
 * Fileprivate funciton for adding source and layers that paitn CV-19 confirmed cases and deaths throughout the states and municipalities of CA and MX.
 *
 * @param map
 */

const addStatesAndMunicipalities = (map?: Map): void => {
  if (map && !map.getSource(mapboxStatesAndMunicipalities.sourceId)) {
    map.addSource(
      mapboxStatesAndMunicipalities.sourceId,
      statesAndMunicipalitiesSource
    );
    map.addLayer(governmentCovidCasesStatesAndMunicipalitiesFills as AnyLayer);
    map.addLayer(governmentCovidDeathsStatesAndMunicipalitiesFills as AnyLayer);
  }
};

/**
 * Fileprivate funciton that moves up layers that display country, state and settlemente names, so that they can be seen on top of the virus outbreak data.
 *
 * @param map
 */

const moveGeographyLayersToFront = (map: Map) => {
  map.moveLayer('settlement-label');
  map.moveLayer('state-label');
  map.moveLayer('country-label');
};
