import { Map } from 'mapbox-gl';
import {
  MapCovidFeature,
  mapboxUSACountyBoundaries,
  mapboxStatesAndMunicipalities,
} from '../layers';

export const joinGovernmentCovidData = (
  map: Map,
  country: string,
  features: never[],
  adm1Boundaries: unknown
): void => {
  if (country === 'US') joinUSData(map, features);
  else joinNonUSData(map, features, adm1Boundaries);
};

/**
 * This function merges covid data from the US government into the map by joining the data that we receive from our backend with the county boundaries source from mapbox.
 *
 * @param map
 * @param features
 */

const joinUSData = (map: Map, features: unknown[]): void => {
  if (!map.getSource(mapboxUSACountyBoundaries.sourceId)) return;

  features.forEach(({ id, properties }) => {
    map.setFeatureState(
      {
        id, // FIPS code
        source: mapboxUSACountyBoundaries.sourceId,
        sourceLayer: mapboxUSACountyBoundaries.sourceLayerId,
      },
      {
        [MapCovidFeature.DEATHS]: properties.paintDeath,
        [MapCovidFeature.CONFIRMED_CASES]: properties.paintConfirm,
      }
    );
  });
};

/**
 * GraphQL from service layer brings the adm1Boundaries object.
 *
 * Once that is available, filter for CA and MX obj mapbox data-join with field iso_3166_1 in lookupTable with properties.state from our JHU data.
 *
 * @param map
 * @param features
 */

const joinNonUSData = async (
  map: Map,
  features: unknown[],
  adm1Boundaries: unknown
): Promise<void> => {
  if (!map.getSource(mapboxStatesAndMunicipalities.sourceId)) return;

  const lookupData = filterLookupTable(adm1Boundaries);

  features.forEach(({ properties }) => {
    let stateName = properties.state;

    if (mxJhuStateNameDiscrepancies[stateName]) {
      stateName = mxJhuStateNameDiscrepancies[stateName];
    }

    const idFromLookup = lookupData[stateName]
      ? lookupData[stateName].feature_id
      : '';

    if (stateName && idFromLookup) {
      map.setFeatureState(
        {
          id: idFromLookup, // this should match the look up field we are tying our boundaries to match with
          source: mapboxStatesAndMunicipalities.sourceId,
          sourceLayer: mapboxStatesAndMunicipalities.sourceLayerId,
        },
        {
          [MapCovidFeature.DEATHS]: properties.paintDeath,
          [MapCovidFeature.CONFIRMED_CASES]: properties.paintConfirm,
        }
      );
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterLookupTable = (adm1Boundaries: any): any => {
  const lookupData = {};

  for (let i = 0; i < adm1Boundaries.edges.length; i++) {
    const nodeview = adm1Boundaries.edges[i].node;
    if (nodeview && nodeview !== '') {
      if (nodeview['iso_3166_1'] === 'MX' || nodeview['iso_3166_1'] === 'CA') {
        lookupData[nodeview.name] = nodeview;
      }
    }
  }

  return lookupData;
};

export const mxJhuStateNameDiscrepancies = {
  'Ciudad de Mexico': 'Ciudad de México',
  Coahuila: 'Coahuila de Zaragoza',
  Mexico: 'México',
  Michoacan: 'Michoacán de Ocampo',
  'Nuevo Leon': 'Nuevo León',
  Queretaro: 'Querétaro',
  'San Luis Potosi': 'San Luis Potosí',
  Veracruz: 'Veracruz de Ignacio de la Llave',
  Yucatan: 'Yucatán',
};
