import { AnySourceData, FillLayer, LineLayer } from 'mapbox-gl';

export const governmentFluDataSourceId = 'FLU_USA';

let cdcDataUrl = '/api/nonuserstats/cdcfludata';
if (process.env.GATSBY_ONM_API_DEV_URL) {
  cdcDataUrl = process.env.GATSBY_ONM_API_DEV_URL + '/nonuserstats/cdcfludata';
}

export const governmentFluDataSource: AnySourceData = {
  type: 'geojson',
  data: cdcDataUrl,
};

export enum GovernmentFluDataLayerId {
  FLU_STATES_FILLS_US = 'FLU_STATES_FILLS_US',
  FLU_STATES_BOARDERS_US = 'FLU_STATES_BOARDERS_US',
}

export const fluStatesFillsUSA: FillLayer = {
  id: GovernmentFluDataLayerId.FLU_STATES_FILLS_US,
  source: governmentFluDataSourceId,
  type: 'fill',
  layout: {
    visibility: 'none',
  },
  paint: {
    'fill-color': ['coalesce', ['get', 'activityLevelColor'], '#ffffff'],
    'fill-opacity': 0.8,
  },
};

export const fluStatesBoardersUSA: LineLayer = {
  id: GovernmentFluDataLayerId.FLU_STATES_BOARDERS_US,
  source: governmentFluDataSourceId,
  type: 'line',
  layout: {
    visibility: 'none',
  },
  paint: {
    'line-color': '#fff',
    'line-width': 1,
  },
};

export const governmentFluLayers = [fluStatesFillsUSA, fluStatesBoardersUSA];
export const governmentFluLayersIds = Object.values(GovernmentFluDataLayerId);
