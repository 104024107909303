import { AnySourceData, Layer } from 'mapbox-gl';

export enum GovernmentCovidDataLayerId {
  GOVERNMENT_COVID_CASES_USA_COUNTY_FILLS = 'GOVERNMENT_COVID_CASES_USA_COUNTY_FILLS',
  GOVERNMENT_COVID_DEATHS_USA_COUNTY_FILLS = 'GOVERNMENT_COVID_DEATHS_USA_COUNTY_FILLS',

  GOVERNMENT_COVID_CASES_STATES_AND_MUNICIPALITIES_FILLS = 'GOVERNMENT_COVID_CASES_STATES_AND_MUNICIPALITIES_FILLS',
  GOVERNMENT_COVID_DEATHS_STATES_AND_MUNICIPALITIES_FILLS = 'GOVERNMENT_COVID_DEATHS_STATES_AND_MUNICIPALITIES_FILLS',
}

export enum MapCovidFeature {
  DEATHS = 'covidDeathsFeature',
  CONFIRMED_CASES = 'covidConfirmedCasesFeature',
}

/** ***************************** States and Municipalities ***************************** */

export const mapboxStatesAndMunicipalities = {
  sourceId: 'STATES_AND_MUNICIPALITIES',
  sourceLayerId: 'boundaries_admin_1',
  url: 'mapbox://mapbox.boundaries-adm1-v3',
};

export const statesAndMunicipalitiesSource: AnySourceData = {
  type: 'vector',
  url: mapboxStatesAndMunicipalities.url,
};

const statesAndMunicipalitiesBaseLayer: Omit<Layer, 'id'> = {
  type: 'fill',
  source: mapboxStatesAndMunicipalities.sourceId,
  'source-layer': mapboxStatesAndMunicipalities.sourceLayerId,
  layout: {
    visibility: 'visible',
  },
  paint: {
    'fill-opacity': {
      stops: [
        [1, 0.5],
        [10, 0.8],
      ],
    },
  },
};

export const governmentCovidCasesStatesAndMunicipalitiesFills = {
  ...statesAndMunicipalitiesBaseLayer,
  id:
    GovernmentCovidDataLayerId.GOVERNMENT_COVID_CASES_STATES_AND_MUNICIPALITIES_FILLS,
  filter: [
    'any',
    ['==', ['get', 'iso_3166_1'], 'MX'],
    ['==', ['get', 'iso_3166_1'], 'CA'],
  ],
  paint: {
    ...statesAndMunicipalitiesBaseLayer.paint,
    'fill-color': [
      'case',
      ['!=', ['feature-state', MapCovidFeature.CONFIRMED_CASES], null],
      ['feature-state', MapCovidFeature.CONFIRMED_CASES],
      'rgba(255, 255, 255, 0)',
    ],
  },
};

export const governmentCovidDeathsStatesAndMunicipalitiesFills = {
  ...statesAndMunicipalitiesBaseLayer,
  id:
    GovernmentCovidDataLayerId.GOVERNMENT_COVID_DEATHS_STATES_AND_MUNICIPALITIES_FILLS,
  layout: {
    visibility: 'none',
  },
  filter: [
    'any',
    ['==', ['get', 'iso_3166_1'], 'MX'],
    ['==', ['get', 'iso_3166_1'], 'CA'],
  ],
  paint: {
    ...statesAndMunicipalitiesBaseLayer.paint,
    'fill-color': [
      'case',
      ['!=', ['feature-state', MapCovidFeature.DEATHS], null],
      ['feature-state', MapCovidFeature.DEATHS],
      'rgba(255, 255, 255, 0)',
    ],
  },
};

/** ***************************** USA Counties ***************************** */

export const mapboxUSACountyBoundaries = {
  sourceId: 'US_COUNTY_BOUNDARIES',
  sourceLayerId: 'tl_2019_us_county',
  url: 'mapbox://healthmap.tl_2019_us_county',
};

export const usaCountyBoundariesSource: AnySourceData = {
  type: 'vector',
  url: mapboxUSACountyBoundaries.url,
};

const usaCountyBoundariesBaseLayer: Omit<Layer, 'id'> = {
  source: mapboxUSACountyBoundaries.sourceId,
  'source-layer': mapboxUSACountyBoundaries.sourceLayerId,
  type: 'fill',
  layout: {
    visibility: 'visible',
  },
  paint: {
    'fill-opacity': {
      stops: [
        [1, 0.5],
        [10, 0.8],
      ],
    },
  },
};

export const governmentCovidCasesUSACountyFills = {
  ...usaCountyBoundariesBaseLayer,
  id: GovernmentCovidDataLayerId.GOVERNMENT_COVID_CASES_USA_COUNTY_FILLS,
  paint: {
    ...usaCountyBoundariesBaseLayer.paint,
    'fill-color': [
      'case',
      ['!=', ['feature-state', MapCovidFeature.CONFIRMED_CASES], null],
      ['feature-state', MapCovidFeature.CONFIRMED_CASES],
      'rgba(255, 255, 255, 0)',
    ],
  },
};

export const governmentCovidDeathsUSACountyFills = {
  ...usaCountyBoundariesBaseLayer,
  id: GovernmentCovidDataLayerId.GOVERNMENT_COVID_DEATHS_USA_COUNTY_FILLS,
  layout: {
    visibility: 'none',
  },
  paint: {
    ...usaCountyBoundariesBaseLayer.paint,
    'fill-color': [
      'case',
      ['!=', ['feature-state', MapCovidFeature.DEATHS], null],
      ['feature-state', MapCovidFeature.DEATHS],
      'rgba(255, 255, 255, 0)',
    ],
  },
};

/** ***************************** Utils ***************************** */

export const governmentCovidLayers = [
  governmentCovidCasesUSACountyFills,
  governmentCovidDeathsUSACountyFills,
  governmentCovidCasesStatesAndMunicipalitiesFills,
  governmentCovidDeathsStatesAndMunicipalitiesFills,
];

export const governmentCovidLayersIds = Object.values(
  GovernmentCovidDataLayerId
);

export const governmentCovidCasesLayersIds = [
  GovernmentCovidDataLayerId.GOVERNMENT_COVID_CASES_USA_COUNTY_FILLS,
  GovernmentCovidDataLayerId.GOVERNMENT_COVID_CASES_STATES_AND_MUNICIPALITIES_FILLS,
];

export const governmentCovidDeathsLayersIds = [
  GovernmentCovidDataLayerId.GOVERNMENT_COVID_DEATHS_USA_COUNTY_FILLS,
  GovernmentCovidDataLayerId.GOVERNMENT_COVID_DEATHS_STATES_AND_MUNICIPALITIES_FILLS,
];
