import React, { useContext } from 'react';
import { MapContainer } from './styles';
import { CountryContext } from '~/context/i18n';
import { useMapLayers } from './useMapLayers';
import { useZipcodeSearch } from './useZipcodeSearch';
import { useMapInstantiation } from './useMapInstantiation';
import { useGovernmentFluData } from './useGovernmentFluData';
import { useGovernmentCovidData } from './useGovernmentCovidData';
import { useUserReportedSymptomsData } from './useUserReportedSymptomsData';

export const Map: React.FC = () => {
  const { country } = useContext(CountryContext);
  const { map } = useMapInstantiation({
    container: 'map',
    center: country.coordinates.center,
    zoom: country.coordinates.zoomLevel,
  });
  useMapLayers(map);
  useZipcodeSearch(map);
  useGovernmentFluData(map);
  useGovernmentCovidData(map);
  useUserReportedSymptomsData(map);

  return <MapContainer />;
};

export default Map;
