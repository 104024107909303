/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { CountryContext } from '~/context/i18n';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import {
  getUserReportedSymptomsMarkers,
  GetUserReportedSymptomsMarkersResponse,
} from '~/requests/reports';
import { useSnackbar } from './useSnackbar';
import { useTranslation } from 'react-i18next';

interface UseSymptomsMarkers {
  geoJsonMarkerData: GetUserReportedSymptomsMarkersResponse;
}

export const useSymptomsMarkers = (): UseSymptomsMarkers => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const { showSnackbar } = useSnackbar();
  const { statsByCountry } = useGlobalState();
  const { country } = useContext(CountryContext);

  const countryCode = country.code.toUpperCase();
  const { geoJsonMarkerData } = statsByCountry[countryCode] || {};

  const setLoading = loading =>
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading,
        loader: 'isGeoJsonMarkerDataLoading',
      },
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getUserReportedSymptomsMarkers(countryCode);
        dispatch({
          type: 'SET_MARKERS_FOR_COUNTRY',
          payload: {
            country: countryCode,
            markerGeoJsonData: data,
          },
        });
      } catch (error) {
        showSnackbar(t('LocationStats.userStats.error'), 'error');
      } finally {
        setLoading(false);
      }
    };

    if (!geoJsonMarkerData) {
      fetchData();
    }
  }, [countryCode]);

  return { geoJsonMarkerData };
};
