import {
  EventData,
  LngLatLike,
  MapMouseEvent,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';

/**
 * Ensures that if the map is zoomed out such that multiple copies of the feature are visible.
 *
 * The popup appears over the copy being pointed to.
 *
 * @param coords: number[]
 * @param e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
 *
 * @returns coordinates: LngLatLike as [number, number]
 */

export const getCoordinatesForPopup = (
  coords: number[],
  e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
): LngLatLike => {
  const coordinates: number[] = [...coords];
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }
  return coordinates as [number, number];
};
