import { useState, useEffect, useContext } from 'react';
import { MAPBOX_ACCESS_TOKEN } from '~/config';
import { CountryContext } from '~/context/i18n';
import mapbox, { Map, MapboxOptions } from 'mapbox-gl';
import { defaultInstantiationOptions } from './constants';

interface UseMapInstantiation {
  map: Map;
}

/**
 * Custom react hook that creates and returns an instance of a map.
 *
 * It accepts an optional instantiationOptions object as its only argument, but will use the default options if none are passed.
 *
 * By default, it'll render the map inside a container element with an id attribute of 'map'.
 *
 * It instantiates the map with a useEffect hook that has the instantiationOptions param as its only dependency. It stores the map instance in local state and exposes it.
 * @param instantiationOptions
 */

export const useMapInstantiation = (
  instantiationOptions?: MapboxOptions
): UseMapInstantiation => {
  const [map, setMap] = useState<Map>();
  const { country } = useContext(CountryContext);

  useEffect(() => {
    mapbox.accessToken = MAPBOX_ACCESS_TOKEN;

    if (!mapbox.supported()) {
      return alert("this browser doesn't support Mapbox.");
    }

    const mapboxMap = new Map({
      ...defaultInstantiationOptions,
      ...instantiationOptions,
    });

    mapboxMap.scrollZoom.disable();
    mapboxMap.addControl(new mapbox.NavigationControl());

    mapboxMap.on('load', () => {
      setMap(mapboxMap);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return { map };
};
