/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from '~/store';
import { Map } from 'mapbox-gl';
import {
  isVisualizingFluData,
  isVisualizingCovidData,
  isVisualizingCovidCasesData,
  isVisualizingCovidDeathsData,
} from '~/store/map/filters';
import {
  governmentFluLayersIds,
  governmentCovidLayersIds,
  governmentCovidCasesLayersIds,
  governmentCovidDeathsLayersIds,
  UserReportedSymptomsDataLayerId,
} from './layers';

/**
 * Hook for managing the map's layers.
 *
 * It implements a dedicated useEffect for each filter in the global store. This enables us to update only layers that need to be updated, and minimize calls to map methods.
 *
 * This hook goes hand in hand with useMapFilters, which exposes an API for updating the filters.
 *
 * @param map
 */

export const useMapLayers = (map: Map): void => {
  const {
    userReportedFluSymptoms,
    userReportedCovidSymptoms,
    userReportedOtherSymptoms,
    userReportedNoSymptoms,
    governmentData,
    governmentDataVirus,
    governmentCovidActivity,
  } = useSelector(state => state.mapFilters);

  const toggleLayers = (
    layersIds: string[],
    visibility: 'visible' | 'none'
  ) => {
    if (map) {
      for (const layerId of layersIds) {
        const mapHasLayer = !!map.getLayer(layerId);
        if (mapHasLayer) {
          map.setLayoutProperty(layerId, 'visibility', visibility);
        }
      }
    }
  };

  const updateGovernmentCovidLayersVisibility = () => {
    toggleLayers(
      governmentCovidCasesLayersIds,
      isVisualizingCovidCasesData(governmentCovidActivity) ? 'visible' : 'none'
    );
    toggleLayers(
      governmentCovidDeathsLayersIds,
      isVisualizingCovidDeathsData(governmentCovidActivity) ? 'visible' : 'none'
    );
  };

  const updateGovernmentVirusLayersVisibility = () => {
    if (isVisualizingFluData(governmentDataVirus)) {
      toggleLayers(governmentFluLayersIds, 'visible');
      toggleLayers(governmentCovidLayersIds, 'none');
    } else if (isVisualizingCovidData(governmentDataVirus)) {
      toggleLayers(governmentFluLayersIds, 'none');
      updateGovernmentCovidLayersVisibility();
    }
  };

  useEffect(
    function toggleUserReportedCovidSymptoms() {
      toggleLayers(
        [UserReportedSymptomsDataLayerId.USER_REPORTED_COVID_SYMPTOMS_MARKERS],
        userReportedCovidSymptoms ? 'visible' : 'none'
      );
    },
    [userReportedCovidSymptoms]
  );

  useEffect(
    function toggleUserReportedFluSymptoms() {
      toggleLayers(
        [UserReportedSymptomsDataLayerId.USER_REPORTED_FLU_SYMPTOMS_MARKERS],
        userReportedFluSymptoms ? 'visible' : 'none'
      );
    },
    [userReportedFluSymptoms]
  );

  useEffect(
    function toggleUserReportedOtherSymptoms() {
      toggleLayers(
        [UserReportedSymptomsDataLayerId.USER_REPORTED_OTHER_SYMPTOMS_MARKERS],
        userReportedOtherSymptoms ? 'visible' : 'none'
      );
    },
    [userReportedOtherSymptoms]
  );

  useEffect(
    function toggleUserReportedNoSymptoms() {
      toggleLayers(
        [UserReportedSymptomsDataLayerId.USER_REPORTED_NO_SYMPTOMS_MARKERS],
        userReportedNoSymptoms ? 'visible' : 'none'
      );
    },
    [userReportedNoSymptoms]
  );

  useEffect(
    function toggleGovernmentCovidActivityLayers() {
      if (governmentData) {
        updateGovernmentCovidLayersVisibility();
      }
    },
    [governmentCovidActivity, governmentData]
  );

  useEffect(
    function toggleGovernmentVirusLayers() {
      if (governmentData) {
        updateGovernmentVirusLayersVisibility();
      }
    },
    [governmentDataVirus, governmentData]
  );

  useEffect(
    function toggleAllGovernmentDataLayers() {
      if (governmentData) {
        updateGovernmentVirusLayersVisibility();
      } else {
        toggleLayers(governmentFluLayersIds, 'none');
        toggleLayers(governmentCovidLayersIds, 'none');
      }
    },
    [governmentData]
  );
};
