import { MapboxOptions } from 'mapbox-gl';

export const defaultInstantiationOptions: MapboxOptions = {
  container: 'map',
  style: 'mapbox://styles/mapbox/light-v10',
  minZoom: 2,
  maxZoom: 12,
  zoom: 3,
  interactive: true,
};
