import { useEffect } from 'react';
import { Map } from 'mapbox-gl';
import {
  fluStatesFillsUSA,
  fluStatesBoardersUSA,
  governmentFluDataSource,
  governmentFluDataSourceId,
} from './layers/governmentFluData';

export const useGovernmentFluData = (map: Map): void => {
  useEffect(() => {
    if (map && !map.getSource(governmentFluDataSourceId)) {
      map.addSource(governmentFluDataSourceId, governmentFluDataSource);
      map.addLayer(fluStatesFillsUSA, 'waterway-label');
      map.addLayer(fluStatesBoardersUSA);
    }
  }, [map]);
};
