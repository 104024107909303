import { useTranslation } from 'react-i18next';
import { getCoordinatesForPopup } from './helpers/mapPopup';
import {
  SymptomsFeatures,
  featureToLayerMap,
  symptomsFeatureIds,
  featureToTranslationMap,
} from './layers/userReportedSymptomsData';
import {
  Map,
  Popup,
  EventData,
  MapMouseEvent,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';

interface UseSymptomsPopup {
  hidePopup: () => void;
  showPopup: (
    e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
  ) => void;
}

export const useSymptomsPopup = (map: Map): UseSymptomsPopup => {
  const { t, i18n } = useTranslation();

  const popup = new Popup({
    closeButton: false,
    closeOnClick: false,
  });

  const hidePopup = () => {
    map.getCanvas().style.cursor = 'grab';
    popup.remove();
  };

  const buildPopupContent = (featureProps: SymptomsFeatures): string => {
    const popupContent: string[] = [
      `${t('LocationStats.userStats.zipcode')}: ${featureProps.zipcode}`,
    ];

    for (const symptom of symptomsFeatureIds) {
      const layerId = featureToLayerMap[symptom];
      const layerVisibility = map.getLayoutProperty(layerId, 'visibility');
      const symptomCount = featureProps[symptom];

      if (layerVisibility === 'visible' && symptomCount) {
        const translatedSymptom = t(
          `LocationStats.userStats.${featureToTranslationMap[symptom]}`
        );
        popupContent.push(
          `${translatedSymptom}: ${symptomCount.toLocaleString(i18n.language)}`
        );
      }
    }

    return popupContent.join('<br>');
  };

  const showPopup = (
    e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
  ) => {
    const symptomsFeatures = e.features?.[0]?.properties as SymptomsFeatures;
    const popupContent = buildPopupContent(symptomsFeatures);
    const popupHasRelevantContent = popupContent.length > 1;

    if (popupHasRelevantContent) {
      map.getCanvas().style.cursor = 'pointer';
      const popupCoordinates = getCoordinatesForPopup(e.lngLat.toArray(), e);

      popup
        .setLngLat(popupCoordinates)
        .setHTML(popupContent)
        .addTo(map);
    }
  };

  return { showPopup, hidePopup };
};
