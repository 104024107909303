import { useEffect } from 'react';
import { Map, AnyLayer } from 'mapbox-gl';
import { useSymptomsPopup } from './useSymptomsPopup';
import { useSymptomsMarkers } from '~/state/hooks/useSymptomsMarkers';
import {
  userReportedSymptomsLayers,
  userReportedSymptpmsDataSourceId,
} from './layers/userReportedSymptomsData';

export const useUserReportedSymptomsData = (map?: Map): void => {
  const { showPopup, hidePopup } = useSymptomsPopup(map);
  const { geoJsonMarkerData: userReportedSymptomsData } = useSymptomsMarkers();

  useEffect(() => {
    if (!map) {
      return;
    }

    const dataHasBeenFetched = !!userReportedSymptomsData?.features?.length;
    const sourceHasNotBeenAdded = !map.getSource(
      userReportedSymptpmsDataSourceId
    );

    if (dataHasBeenFetched && sourceHasNotBeenAdded) {
      map.addSource(userReportedSymptpmsDataSourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: userReportedSymptomsData.features,
        },
      });

      for (const layer of userReportedSymptomsLayers) {
        map.addLayer(layer as AnyLayer);
        map.on('mouseenter', layer.id, showPopup);
        map.on('mouseleave', layer.id, hidePopup);
        map.moveLayer(layer.id);
      }

      map.on('data', () => {
        for (const layer of userReportedSymptomsLayers) {
          if (map.getLayer(layer.id)) {
            map.moveLayer(layer.id);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, userReportedSymptomsData]);
};
