import { Layer } from 'mapbox-gl';
import { symptomsColors } from '~/context/Theme';

export const userReportedSymptpmsDataSourceId = 'USER_REPORTED_SYMPTOMS_DATA';

export enum UserReportedSymptomsDataLayerId {
  USER_REPORTED_FLU_SYMPTOMS_MARKERS = 'USER_REPORTED_FLU_SYMPTOMS_MARKERS',
  USER_REPORTED_COVID_SYMPTOMS_MARKERS = 'USER_REPORTED_COVID_SYMPTOMS_MARKERS',
  USER_REPORTED_OTHER_SYMPTOMS_MARKERS = 'USER_REPORTED_OTHER_SYMPTOMS_MARKERS',
  USER_REPORTED_NO_SYMPTOMS_MARKERS = 'USER_REPORTED_NO_SYMPTOMS_MARKERS',
}

export enum SymptomsFeature {
  COVID = 'symptoms',
  FLU = 'fluSymptoms',
  OTHER = 'otherSymptoms',
  NONE = 'noSymptoms',
}

export interface SymptomsFeatures {
  zipcode: number;
  [SymptomsFeature.COVID]: number;
  [SymptomsFeature.FLU]: number;
  [SymptomsFeature.OTHER]: number;
  [SymptomsFeature.NONE]: number;
}

const userReportedSymptomsBaseLayer: Omit<Layer, 'id'> = {
  source: userReportedSymptpmsDataSourceId,
  type: 'circle',
  layout: {
    visibility: 'none',
  },
  paint: {
    'circle-opacity': {
      stops: [
        [1, 0.5],
        [10, 0.8],
      ],
    },
    'circle-radius': {
      stops: [
        [4, 3],
        [6, 4],
        [8, 5],
      ],
    },
  },
};

const userReportedCovidSymptomsLayer: Layer = {
  ...userReportedSymptomsBaseLayer,
  id: UserReportedSymptomsDataLayerId.USER_REPORTED_COVID_SYMPTOMS_MARKERS,
  filter: ['>=', ['get', SymptomsFeature.COVID], 1],
  layout: {
    visibility: 'visible',
  },
  paint: {
    ...userReportedSymptomsBaseLayer.paint,
    'circle-color': symptomsColors.covidSymptom,
    'circle-translate': {
      stops: [
        [3, [-1, 0]],
        [6, [-2, 0]],
        [8, [-3, 0]],
      ],
    },
  },
};

const userReportedFluSymptomsLayer: Layer = {
  ...userReportedSymptomsBaseLayer,
  id: UserReportedSymptomsDataLayerId.USER_REPORTED_FLU_SYMPTOMS_MARKERS,
  filter: ['>=', ['get', SymptomsFeature.FLU], 1],
  layout: {
    visibility: 'visible',
  },
  paint: {
    ...userReportedSymptomsBaseLayer.paint,
    'circle-color': symptomsColors.fluSymptom,
  },
};

const userReportedOtherSymptomsLayer: Layer = {
  ...userReportedSymptomsBaseLayer,
  id: UserReportedSymptomsDataLayerId.USER_REPORTED_OTHER_SYMPTOMS_MARKERS,
  filter: ['>=', ['get', SymptomsFeature.OTHER], 1],
  paint: {
    ...userReportedSymptomsBaseLayer.paint,
    'circle-color': symptomsColors.otherSymptom,
  },
};

const userReportedNoSymptomsLayer: Layer = {
  ...userReportedSymptomsBaseLayer,
  id: UserReportedSymptomsDataLayerId.USER_REPORTED_NO_SYMPTOMS_MARKERS,
  filter: ['>=', ['get', SymptomsFeature.NONE], 1],
  paint: {
    ...userReportedSymptomsBaseLayer.paint,
    'circle-color': symptomsColors.noSymptom,
    'circle-translate': {
      stops: [
        [3, [1, 0]],
        [6, [2, 0]],
        [8, [3, 0]],
      ],
    },
  },
};

export const userReportedSymptomsLayers = [
  userReportedFluSymptomsLayer,
  userReportedCovidSymptomsLayer,
  userReportedOtherSymptomsLayer,
  userReportedNoSymptomsLayer,
];

export const userReportedSymptomsDataLayerIds = Object.values(
  UserReportedSymptomsDataLayerId
);

export const symptomsFeatureIds = Object.values(SymptomsFeature);

export const featureToLayerMap = {
  [SymptomsFeature.COVID]:
    UserReportedSymptomsDataLayerId.USER_REPORTED_COVID_SYMPTOMS_MARKERS,
  [SymptomsFeature.FLU]:
    UserReportedSymptomsDataLayerId.USER_REPORTED_FLU_SYMPTOMS_MARKERS,
  [SymptomsFeature.OTHER]:
    UserReportedSymptomsDataLayerId.USER_REPORTED_OTHER_SYMPTOMS_MARKERS,
  [SymptomsFeature.NONE]:
    UserReportedSymptomsDataLayerId.USER_REPORTED_NO_SYMPTOMS_MARKERS,
};

export const featureToTranslationMap = {
  [SymptomsFeature.COVID]: 'covidSymptom',
  [SymptomsFeature.FLU]: 'fluSymptom',
  [SymptomsFeature.OTHER]: 'otherSymptom',
  [SymptomsFeature.NONE]: 'noSymptom',
};
