/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { MAPBOX_ACCESS_TOKEN } from '~/config';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '~/context/i18n';
import { Map, LngLatBoundsLike } from 'mapbox-gl';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { useZipCode } from '~/components/forms/MapStatsForm/useZipCode';
import { userReportedSymptomsLayers } from './layers/userReportedSymptomsData';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import axios from 'axios';

export const useZipcodeSearch = (map?: Map): void => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const { clearSearchZipCode } = useZipCode();
  const { country } = useContext(CountryContext);
  const { showSnackbar } = useSnackbar();
  const {
    searchZipCode,
    statsByCountry,
    zipCodeSearchError,
  } = useGlobalState();

  const countryCode = country.code.toUpperCase();

  const setZipCodeSearchError = (errorMessage: string) => {
    dispatch({
      type: 'SET_MAP_ZIPCODE_SEARCH_ERROR',
      payload: { errorMessage },
    });
  };

  const getZipcodeDataFromStore = () => {
    const zipcode = searchZipCode.toUpperCase();
    const features =
      statsByCountry[countryCode]?.geoJsonMarkerData?.features ?? [];
    return features.find(
      marker => marker.properties.zipcode.toUpperCase() === zipcode
    );
  };

  const hideMarkersOutsideOfZipcodeArea = () => {
    const zipcode = searchZipCode.toUpperCase();
    for (const layer of userReportedSymptomsLayers) {
      const mapHasLayer = !!map.getLayer(layer.id);
      const zipcodeFilter = ['==', ['get', 'zipcode'], zipcode];
      if (mapHasLayer) {
        map.setFilter(layer.id, ['all', zipcodeFilter, layer.filter]);
      }
    }
  };

  const showAllMarkers = () => {
    for (const layer of userReportedSymptomsLayers) {
      const mapHasLayer = !!map.getLayer(layer.id);
      if (mapHasLayer) {
        map.setFilter(layer.id, layer.filter);
      }
    }
  };

  const fetchMapboxDataForZipcode = async () => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchZipCode}.json?fuzzyMatch=false&country=${countryCode}&access_token=${MAPBOX_ACCESS_TOKEN}`;
    const {
      data: { features = [] },
    } = await axios.get(url);

    if (!features.length) {
      throw Error(t('LocationStats.locationNotFound'));
    }

    const zipData = features.find(item => item?.place_type?.[0] === 'postcode');
    if (!zipData) {
      showSnackbar(t('LocationStats.noUserDataForZipcode'), 'info');
    }

    return zipData || features[0];
  };

  useEffect(
    function zoomOutToFullCountry() {
      if (map && !searchZipCode) {
        showAllMarkers();

        map.flyTo({
          center: country.coordinates.center,
          zoom: country.coordinates.zoomLevel,
        });

        const countryBox: LngLatBoundsLike = [
          country.coordinates.southWestCorner,
          country.coordinates.northEastCorner,
        ];

        map.fitBounds(countryBox, { padding: 40 });
      }
    },
    [map, searchZipCode]
  );

  useEffect(
    function zoomIntoZipcodeArea() {
      if (map && searchZipCode) {
        hideMarkersOutsideOfZipcodeArea();
        const data = getZipcodeDataFromStore();

        if (data) {
          map.flyTo({ zoom: 9, center: data.geometry.coordinates });
        } else {
          fetchMapboxDataForZipcode()
            .then(data => {
              map.flyTo({ zoom: 9, center: data.geometry.coordinates });
            })
            .catch(err => {
              setZipCodeSearchError(err.message);
              clearSearchZipCode();
            });
        }
      }
    },
    [map, searchZipCode]
  );

  useEffect(
    function showErrorSnackbar() {
      if (zipCodeSearchError) {
        showSnackbar(zipCodeSearchError, 'error', () =>
          setZipCodeSearchError('')
        );
      }
    },
    [zipCodeSearchError]
  );
};
